import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from "./routes"
import {
  getAuth,
  onAuthStateChanged
} from "firebase/auth";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const auth = getAuth();
  const unsubscribe = onAuthStateChanged(auth, (user) => {
    if (user) {
      if (requiresAuth) {
        next()
      } else {
        next({
          path: '/akiya'
        })
    }}
    else {
      if (requiresAuth) {
        next({
          path: '/login'
        })
      } else {
        next()
      }
    }
  })

  unsubscribe()
})
export default router