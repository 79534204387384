const pageLists = [
  {
    path: '1',
    name: 'Page1_akiya',
    component:() => import("../components/akiya/Page1.vue"),
    meta: {
      type: "akiya",
      index: 1
    }
  },
  {
    path: '2',
    name: 'Page2_akiya',
    component:() => import("../components/akiya/Page2.vue"),
    meta: {
      type: "akiya",
      index: 2
    }
  },
  {
    path: '3',
    name: 'Page3_akiya',
    component:() => import("../components/akiya/Page3.vue"),
    meta: {
      type: "akiya",
      index: 3
    }
  },
  {
    path: '4',
    name: 'Page4_akiya',
    component:() => import("../components/akiya/Page4.vue"),
    meta: {
      type: "akiya",
      index: 4
    }
  },
  {
    path: '5',
    name: 'Page5_akiya',
    component:() => import("../components/akiya/Page5.vue"),
    meta: {
      type: "akiya",
      index: 5
    }
  },
  {
    path: '6',
    name: 'Page6_akiya',
    component:() => import("../components/akiya/Page6.vue"),
    meta: {
      type: "akiya",
      index: 6
    }
  },
  {
    path: '7',
    name: 'Page7_akiya',
    component:() => import("../components/akiya/Page7.vue"),
    meta: {
      type: "akiya",
      index: 7
    }
  },
  {
    path: '8',
    name: 'Page8_akiya',
    component:() => import("../components/akiya/Page8.vue"),
    meta: {
      type: "akiya",
      index: 8
    }
  },
  {
    path: '9',
    name: 'Page9_akiya',
    component:() => import("../components/akiya/Page9.vue"),
    meta: {
      type: "akiya",
      index: 9
    }
  },
  {
    path: '10',
    name: 'Page10_akiya',
    component:() => import("../components/akiya/Page10.vue"),
    meta: {
      type: "akiya",
      index: 10
    }
  },
  {
    path: '11',
    name: 'Page11_akiya',
    component:() => import("../components/akiya/Page11.vue"),
    meta: {
      type: "akiya",
      index: 11
    }
  },
  {
    path: '12',
    name: 'Page12_akiya',
    component:() => import("../components/akiya/Page12.vue"),
    meta: {
      type: "akiya",
      index: 12
    }
  },
  {
    path: '13',
    name: 'Page13_akiya',
    component:() => import("../components/akiya/Page13.vue"),
    meta: {
      type: "akiya",
      index: 13
    }
  },
  {
    path: '14',
    name: 'Page14_akiya',
    component:() => import("../components/akiya/Page14.vue"),
    meta: {
      type: "akiya",
      index: 14
    }
  },
  {
    path: '15',
    name: 'Page15_akiya',
    component:() => import("../components/akiya/Page15.vue"),
    meta: {
      type: "akiya",
      index: 15
    }
  }
]

export default pageLists