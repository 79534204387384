const formLists = [
  {
    path: '1',
    name: 'form1',
    component:() => import("../components/consult/Form1.vue"),
    meta: {
      type: "consult",
      index: 1
    }
  },
  {
    path: '2',
    name: 'form2',
    component:() => import("../components/consult/Form2.vue"),
    meta: {
      type: "consult",
      index: 2
    }
  },
  {
    path: '3',
    name: 'form3',
    component:() => import("../components/consult/Form3.vue"),
    meta: {
      type: "consult",
      index: 3
    }
  },
  {
    path: '4',
    name: 'form4',
    component:() => import("../components/consult/Form4.vue"),
    meta: {
      type: "consult",
      index: 4
    }
  },
  {
    path: '5',
    name: 'form5',
    component:() => import("../components/consult/Form5.vue"),
    meta: {
      type: "consult",
      index: 5
    }
  },
  {
    path: '6',
    name: 'form6',
    component:() => import("../components/consult/Form6.vue"),
    meta: {
      type: "consult",
      index: 6
    }
  },
  {
    path: '7',
    name: 'form7',
    component:() => import("../components/consult/Form7.vue"),
    meta: {
      type: "consult",
      index: 7
    }
  },
  {
    path: '8',
    name: 'form8',
    component:() => import("../components/consult/Form8.vue"),
    meta: {
      type: "consult",
      index: 8
    }
  },
  {
    path: '9',
    name: 'form9',
    component:() => import("../components/consult/Form9.vue"),
    meta: {
      type: "consult",
      index: 9
    }
  },
  {
    path: '10',
    name: 'form10',
    component:() => import("../components/consult/Form10.vue"),
    meta: {
      type: "consult",
      index: 10
    }
  },
  {
    path: '11',
    name: 'form11',
    component:() => import("../components/consult/Form11.vue"),
    meta: {
      type: "consult",
      index: 11
    }
  },
  {
    path: '12',
    name: 'form12',
    component:() => import("../components/consult/Form12.vue"),
    meta: {
      type: "consult",
      index: 12
    }
  },
  {
    path: '13',
    name: 'form13',
    component:() => import("../components/consult/Form13.vue"),
    meta: {
      type: "consult",
      index: 13
    }
  },
]

export default formLists