const config = {
  kintone_url: "/k/v1/record.json",
  kintone_headers_consult: {
    "X-Cybozu-API-Token": "vekXyIj2OZnE7LewGzVrgqAbJ1wmCCitAXSWVqjZ",
    "Content-Type": "application/json",
  },
  kintone_headers_estimate: {
    "X-Cybozu-API-Token": "r7Ghia0IpZB9oq5mOW9LPlEEkIxwIMAan2A6PzTL",
    "Content-Type": "application/json",
  },
  lambda_url:
    "https://o5ygixpgh3.execute-api.ap-northeast-1.amazonaws.com/akikon",
  firebaseConfig: {
    apiKey: "AIzaSyDBovdrYUWMglxgQlSS1EnMHM1fmPRpeUM",
    authDomain: "akicon-login.firebaseapp.com",
    projectId: "akicon-login",
    storageBucket: "akicon-login.appspot.com",
    messagingSenderId: "295864139715",
    appId: "1:295864139715:web:3af0a54108263ab53d0776",
    measurementId: "G-SWPJG0BZG2",
  },
};

export default config;
