<template>
  <v-app>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </v-app>
</template>

<script>
import axios from "axios"

export default {
  name: 'App',
  data() {
    // 1.関数の定義
    function setHeight() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    // 2.初期化
    setHeight();

    // 3.ブラウザのサイズが変更された時・画面の向きを変えた時に再計算する
    window.addEventListener('resize', setHeight);

    const url = "/k/v1/apis.json";
    axios.get(url, null, {
            headers: this.$config.kintone_headers_estimate,
          }).then((res) => {
      console.log('res');
      console.log(res);
    }).catch(e => {
      console.log('e');
      console.log(e);      
    });
    return {
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-application--wrap {
  min-height: calc(var(--vh, 1vh) * 100) !important;
}

.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}
</style>
